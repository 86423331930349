<template>
  <section class="template-dashboard-view templates-list-view">
    <div class="inner-navbar">
      <div class="left-title">
        <div class="icon-block">
          <div class="icon">
            <img
              src="@/assets/img/icons/menu/company-templates-2.svg"
              alt="icon"
              class="img-fluid"
            />
          </div>
          <div class="icon-text">
            <h3 class="title fw-normal text-dark mb-0 ls-05">
              Notification Templates
            </h3>
          </div>
        </div>
      </div>
      <div class="right-block">
        <div class="switch" v-if="type == 'ENTITY'">
          <el-switch
            v-model="isGlobal"
            active-text="Global"
            inactive-text="Entity"
            @change="fetchAllCompanyTemplates()"
          >
          </el-switch>
        </div>
        <div class="right-search-input">
          <el-input
            placeholder="Search by name,subject,description"
            v-model="search_string"
            @input="searchString"
            clearable
            class="search_input float-right"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="inner-navbar-content top">
      <div
        class="vue-data-table-default"
        v-loading="loading"
        :element-loading-text="loadingText"
      >
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <!-- <el-scrollbar wrap-style="padding: 10px; max-height: 400px;"> -->
          <el-table-column
            label="Template Name"
            id="name"
            prop="name"
            min-width="120"
            sortable="custom"
          >
            <template v-if="scope.row.name" slot-scope="scope">
              <el-tooltip :content="scope.row.name">
                <span>{{ scope.row.name | truncate(15, "...") }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="Subject"
            id="name"
            prop="subject"
            min-width="120"
            sortable="custom"
            v-if="type == 'ENTITY'"
          >
            <template v-if="scope.row.subject" slot-scope="scope">
              <el-tooltip :content="scope.row.subject">
                <span>{{ scope.row.subject | truncate(15, "...") }}</span>
              </el-tooltip>
            </template>
            <template v-else>
              <span>--</span>
            </template>
          </el-table-column>
          <el-table-column label="Entity/Global" id="category" min-width="80">
            <template slot-scope="scope">
              <span
                class="cursor-pointer"
                v-if="scope.row.type && scope.row.type == 'GLOBAL'"
              >
                <el-tag type="success">Global</el-tag>
              </span>
              <span
                class="cursor-pointer"
                v-else-if="scope.row.type && scope.row.type == 'PARENT'"
              >
                <el-tag type="danger">Header & Footer</el-tag>
              </span>
              <span class="cursor-pointer" v-else>
                <el-tag>{{
                  scope.row.entity_id.name | truncate(15, "...")
                }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Description" id="category" min-width="200">
            <template v-if="scope.row.description" slot-scope="scope">
              <el-tooltip :content="scope.row.description">
                <span>{{ scope.row.description | truncate(15, "...") }}</span>
              </el-tooltip>
            </template>
            <template v-else>
              <span>--</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Last Modified"
            id="updated_at"
            min-width="170"
            sortable="custom"
          >
            <template slot-scope="scope" v-if="scope.row.updated_at">
              <span class="fs-8 d-inline-block pr-2 text-muted">
                {{ scope.row.updated_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <span>
                <a @click="onTemplateView(scope.row)" style="border: none"
                  ><el-dropdown-item icon="el-icon-view" command="edit"
                    >Preview</el-dropdown-item
                  ></a
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="100">
            <template slot-scope="scope">
              <span class="fs-8 d-inline-block pr-2 text-muted">
                <el-button
                  size="mini"
                  type="primary"
                  @click="selectTemplate(scope.row)"
                >
                  select
                  <i class="el-icon-top-right"></i>
                </el-button>
              </span>
            </template>
          </el-table-column>
          <!-- </el-scrollbar> -->
        </data-tables-server>
      </div>
    </div>
    <dialog-component
      v-loading.fullscreen.lock="duplicateLoading"
      :title="'Template View'"
      :visible="previewDialogVisible"
      :show-close="true"
      :containerWidth="'60%'"
      :containerHeight="'60%'"
      @before-close="previewDialogVisibleClose"
      v-draggable
    >
      <div class="tableScroll" style="justify-content: center !important">
        <div
          style="margin-left: 90px"
          v-html="this.currentTemplateContent"
        ></div>
      </div>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import entitiesHelper from "@/mixins/EntitiesHelper";
import { configs } from "@/assets/data/general_config.ts";
export default {
  name: "singleEntityTemplatesList",
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDuplicateFormTemplateError",
      "getDuplicateFormTemplateStatus",
    ]),
    ...mapGetters("entityEmailTemplate", [
      "getAllCompanyEmailTemplatesData",
      "getDeleteEmailTemplateStatus",
      "getDuplicateTemplateStatus",
      "getEmailTemplateError",
    ]),
    ...mapGetters("entities", [
      "getAddEntityToCompanyStatus",
      "getAllEntities",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace"]),

    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),

    ...mapGetters("auth", ["getAuthenticatedUser"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [5, 10, 15],
      };
    },
  },
  mixins: [PermissionsHelper, NavigationHelper, entitiesHelper],
  async mounted() {
    this.loading = true;
    await this.fetchAllCompanyTemplates();
    this.loading = false;
  },
  props: ["entity_id", "content", "type", "template_type"],
  data() {
    return {
      formTemplateId: null,
      currentEntityId: "",
      pageSizes: [5, 10, 15],
      duplicateTemplateName: "",
      duplicateLoading: false,
      duplicateFromTemplateDialogVisible: false,
      loading: false,
      selectedCompanyTemplate: {},
      total: 0,
      currentPage: 1,
      pageSize: 0,
      order_type: null,
      order_by: null,
      data: [],
      previewDialogVisible: false,
      timeline: null,
      search_string: "",
      temp_total: 0,
      selected_templates: [],
      currentTemplateContent: "",
      selected_entity_id: "",
      allEntities: [],
      loadingText: "",
      isGlobal: false,
    };
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );
  },
  methods: {
    previewDialogVisibleClose() {
      this.previewDialogVisible = false;
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllCompanyTemplates();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchAllCompanyTemplates();
      }
    },
    handleSelectionChange(val) {
      this.selected_templates = [];
      this.selected_templates = val;
    },
    async fetchAllCompanyTemplates() {
      this.loadingText = "Fetching Email Templates";
      this.loading = true;
      if (this.pageSize == 0) {
        this.pageSize = 10;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      if (this.isGlobal) {
        params["isGlobal"] = true;
      }
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }

      if (this.search_string) {
        params.search_string = this.search_string;
      }
      params.type = this.type;
      params.template_type = this.template_type;
      if (this.entity_id) {
        params.entity_id = this.entity_id;
      }
      await this.$store.dispatch(
        "entityEmailTemplate/allEmailTemplates",
        params
      );

      // this.currentPage = this.getAllCompanyTemplatesData.page
      //   ? parseInt(this.getAllCompanyTemplatesData.page)
      //   : 1;
      this.data = [];
      if (this.getAllCompanyEmailTemplatesData) {
        this.data = this.getAllCompanyEmailTemplatesData.data;
        this.total = this.getAllCompanyEmailTemplatesData.total;
      }
      await Promise.all(
        this.data.map(async (template, i) => {
          let allEntityFields =
            template.type !== "GLOBAL"
              ? await this.fetchEntityDetails(
                  template?.entity_id?._id,
                  true,
                  false,
                  false,
                  false,
                  true,
                  false,
                  template.entity_id
                )
              : [];

          this.data[i].subject = this.parseEmailTemplate(
            /\[\[([^[\]]*)\]\]/g,
            template.subject,
            allEntityFields,
            true
          );
        })
      );
      // this.total = this.getAllCompanyTemplatesData.total
      //   ? parseInt(this.getAllCompanyTemplatesData.total)
      //   : 0;
      // this.data = this.getAllCompanyTemplatesData.data || [];
      this.loading = false;
    },
    async refreshTemplates() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.fetchAllCompanyTemplates();
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllCompanyTemplates();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllCompanyTemplates();
      }
    },

    onTemplateView(template) {
      if (this.getIsMobile) {
        return this.$message.warning(
          "Preview is not available on small versions"
        );
      }
      let parentTemplate;
      if (template.type && template.type == "PARENT") {
        parentTemplate = configs.headerFooterTemplate;
        parentTemplate = parentTemplate.replace(
          "{{header_content}}",
          template.header_content
        );
        parentTemplate = parentTemplate.replace(
          "{{footer_content}}",
          template.footer_content
        );
      } else {
        parentTemplate = configs.parentTemplate;
        parentTemplate = parentTemplate.replace(
          "{{content_data}}",
          template.content
        );
        parentTemplate = parentTemplate.replace(
          "{{company_name}}",
          this.getActiveWorkspace.name
        );
      }
      this.currentTemplateContent = parentTemplate;
      this.currentTemplateName = template.name;
      this.previewDialogVisible = true;
    },
    selectTemplate(scope) {
      if (this.type == "ENTITY") {
        this.content.body = scope.content;
        this.content.subject = scope.subject;
        this.content.mentionedRelationalTableFields =
          scope.mentionedRelationalTableFields || null;
        this.content.relationalTableData = scope.relationalTableData || null;
      } else {
        this.content.header_body = scope.header_content;
        this.content.footer_body = scope.footer_content;
        this.content.name = scope.name;
      }
      this.$emit("closeDialog");
    },
  },
  watch: {
    entity_id: {
      async handler() {
        await this.fetchAllCompanyTemplates();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu__item {
  list-style: none;
  width: 116px;
  /* line-height: 36px; */
  margin: 0;
  color: #606266;
  outline: 0;
}
.template-dashboard-view {
  // width: 90%;
  // display: table;
  margin: 0 auto;
  .search_input {
    // height: 29px !important;
  }
  .add-button {
    height: 40px;
    border: 1.5px solid #285ed3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0em 0.65em;
    color: #285ed3;
    background: #ecf5ff;
    border-color: #b3d8ff;
    &:hover {
      background: #409eff;
      border-color: #409eff;
      color: #ffffff;
    }
  }
  .inner-navbar-content {
    margin-top: 20px;
  }
  .guide-stats-block {
    padding: 1em 0 2em;
    .each-stat {
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      color: #ffffff;
      overflow: hidden;
      min-height: 105px;
      &.stat-card-one {
        background-color: #ff83a1;
      }
      &.stat-card-two {
        background-color: #5673dc;
      }
      &.stat-card-three {
        background-color: #ffbd12;
      }
      .icon {
        position: relative;
        z-index: 3;
        img {
          max-height: 50px;
        }
      }
      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      .stat-content {
        position: relative;
        z-index: 2;
      }
      h3 {
        font-size: 3em;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
      p {
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
      }
    }
    .usage-guide {
      position: relative;
      min-height: 105px;
      display: flex;
      background-color: #ebf0f3;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      overflow: hidden;
      .content {
        position: relative;
        z-index: 2;
      }
      .icon-right {
        width: 200px;
        position: relative;
        z-index: 3;
      }
      h4 {
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
      p {
        font-size: 0.75em;
        line-height: 1.3;
        margin: 0.25em 0 0.75em;
      }
      .el-button {
        font-size: 0.85em;
        padding: 0.35em 0.75em;
        background-color: #6979f8;
        &:hover {
          background-color: #4558e9;
        }
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.templates-list-view {
  .el-table {
    td:first-child {
      .cell {
        text-overflow: unset !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.tableScroll {
  height: 50vh;
  overflow-y: auto;
  overflow-x: auto;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width: 100%;
}
.tableScroll::-webkit-scrollbar {
  width: 0.5em;
}
.tableScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.inner-navbar {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-direction: column;
    .left-title {
      margin-bottom: 10px;
    }
  }
  .left-title {
    .icon-block {
      display: flex;
      .icon {
        width: 30px;
        margin-right: 10px;
      }
      .icon-text {
      }
    }
  }
  .right-block {
    display: flex;
    .right-search-input {
      margin-right: 10px;
      // height: 50px;
      @media (max-width: 650px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    .right-create-button {
    }
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: end;
    }
  }
}
.switch {
  margin: auto;
  margin-right: 20px;
}
</style>
<style lang="scss">
.form-template-list-mobile {
  .el-table__body {
    .el-table__row {
      & > td {
        &:first-child {
          padding: 0px;
        }
      }
    }
  }
}
</style>
